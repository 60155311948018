<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            paysafecard classique<br /><br />

            montant: {{ value }} <br /><br />

            code pin: {{ code }} <br />
            No de Serie: {{ serial }}<br /><br />

            Numéro de suivi: {{ traceCode }}<br />
            ID du terminal: {{ terminalId }} <br /><br />

            Instructions:<br /><br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <ul>
                <li>Selectionnez un webshop et son produit</li>
                <li>cliquez sur paysafecard comme mode de payement et saisissez le Code PIN</li>
                <li>Service: <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a></li>
            </ul>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                La revente de votre paysafecard est interdite. <br />
                Les conditions générales de vente et d’utilisation sont disponibles sur <a target="_blank" href="https://www.paysafecard.com">www.paysafecard.com</a>
            </p>
            <p>paysafecard est un moyen de paiement émis par <strong>paysafecard.com Schweiz GmbH</strong>.</p>
            <p>
                Attention!<br />
                avertissement pour les distributeurs. paysafecard ne peut en aucun cas être commandé par téléphone ou par e-mail.
            </p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
